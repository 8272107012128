import { Types } from './config/types';
import { createContext, useReducer } from 'react';
import { Routes, Route } from 'react-router-dom';
import useLanguage from './hooks/useLanguage';
import ScrollToTop from './hooks/ScrollToTop';
import NavigationTop from './components/NavigationTop';
import Footer from './components/Footer';
import ActionBottom from './components/ActionBottom';
import NavigationBottom from './components/NavigationBottom';
import Home from './pages/Home';
import Products from './pages/Products';
import CookiesPolicy from './pages/CookiesPolicy';
import CreateAccount from './pages/CreateAccount';
import Member from './pages/Member';
import NotFound from './pages/NotFound';
import UnderConstruction from './pages/UnderConstruction';

const {
	CONSOLE_ERROR,
	UNDER_CONSTRUCTION
} = (window as any).env as Types;

const {
	REACT_APP_DEFAULT_LANGUAGE,
	REACT_APP_COOKIE_NAME,
	REACT_APP_COOKIE_EXPIRE
} = (process as any).env as Types;

if (CONSOLE_ERROR === 'off') {
	console.error = () => {}
}

const reducer = (state: object, action: Types) => {
	switch(action.type) {
		case 'change_language':
			return { ...state, language: action.payload };
		default:
			console.error(`Unknown action: ${ action.type }`);
			return { state };
	}
}

const dataReducer = {
	language: useLanguage.get(useLanguage.lang(window.location.pathname)!, REACT_APP_DEFAULT_LANGUAGE, REACT_APP_COOKIE_NAME, REACT_APP_COOKIE_EXPIRE)
}

const dataContext = createContext({});

const { warning: languageWarning } = dataReducer.language as Types;
console.log(`%c${ languageWarning.heading }`, 'font-weight: bold; color: red;');
console.log(languageWarning.description);

const App = () => {
	const [ state, dispatch ] = useReducer(reducer, dataReducer);
	const { code: languageCode } = state.language as Types;
	
	return (
		<dataContext.Provider value={{ state, dispatch }}>
			<ScrollToTop />
			{ UNDER_CONSTRUCTION === 'on'
				? <>
					<NavigationTop />
					<div className='fill-top' />
					<Routes>
						<Route path='*' element={ <UnderConstruction /> } />
					</Routes>
				</>
				: <>
					<NavigationTop />
					<div className='fill-top' />
					<Routes>
						<Route index element={ <Home /> } />
						<Route path={ `${ languageCode }/products` } element={ <Products /> }>
							<Route path=':productsId' element={ <Products /> } />
						</Route>
						<Route path={ `${ languageCode }/cookies-policy` } element={ <CookiesPolicy /> } />
						<Route path={ `${ languageCode }/create-account` } element={ <CreateAccount /> } />
						<Route path={ `${ languageCode }/member` } element={ <Member /> } />
						<Route path='*' element={ <NotFound /> } />
					</Routes>
					<Footer />
					<div className='fill-bottom'>
						<div className='safearea-bottom' />
					</div>
					<ActionBottom />
					<NavigationBottom />
				</>
			}
		</dataContext.Provider>
	);
}

export { dataContext };
export default App;