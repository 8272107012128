const useHandler = {
	image: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
		const style = (event.target as HTMLElement).style;
		
		switch(event.type) {
			case 'load':
				if (style.display === 'none') style.display = '';
				break;
			case 'error':
				style.display = 'none';
				break;
			default:
				return null;
		}
	}
}

export default useHandler;