import { Types } from '../config/types';
import { useContext, useRef } from 'react';
import { dataContext } from '../App';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// #region Styled

const Footers = styled.footer`
	padding: var(--spacing-unit-default) var(--spacing-unit-s1);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
`;

const Navigation = styled.nav`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--spacing-unit-default) var(--spacing-unit-s1);
`;

const Wrapper = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
	
	div:first-child {
		font-size: var(--font-size-default);
		font-weight: bold;
		line-height: var(--line-height-default);
		color: var(--black-color);
	}
	
	div:last-child {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: var(--spacing-unit-s3);
	}
`;

const NavLinks = styled(NavLink)`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	
	&.active {
		text-decoration: unset;
		color: var(--primary-color);
	}
`;

const Subscribe = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
	
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: var(--spacing-unit-s1);
	}
	
	input:first-child {
		text-align: center;
		width: 100%;
	}
`;

const Head = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
	
	div:first-child {
		font-size: var(--font-size-default);
		font-weight: bold;
		line-height: var(--line-height-default);
		color: var(--black-color);
	}
	
	div:last-child {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: var(--black-color);
	}
`;

const Law = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
	
	div {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: var(--black-color);
	}
`;

// #endregion

const Footer = () => {
	const { state } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		name: languageName,
		text: languageText,
		registered: languageRegistered,
		copyright: languageCopyright
	} = state.language as Types;
	const formRef = useRef<HTMLFormElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	
	const submitSubscribe = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		
		if (emailRef.current!.value) {
			
			
			
			console.log(`Subscribe = Email : ${ emailRef.current!.value }`);
			
			
			
			formRef.current?.reset();
		}
	}
	
	return (
		<Footers>
			<Navigation>
				<Wrapper>
					<div>{ languageName }</div>
					<div>
						<NavLinks to={ `/${ languageCode }/about-us` } title={ languageText.about_us } className='link-primary'>{ languageText.about_us }</NavLinks>
						<NavLinks to={ `/${ languageCode }/social-responsibility` } title={ languageText.social_responsibility } className='link-primary'>{ languageText.social_responsibility }</NavLinks>
						<NavLinks to={ `/${ languageCode }/careers` } title={ languageText.careers } className='link-primary'>{ languageText.careers }</NavLinks>
					</div>
				</Wrapper>
				<Wrapper>
					<div>{ languageText.family }</div>
					<div>
						<NavLinks to={ `/${ languageCode }/create-account` } title={ languageText.create_account } className='link-primary'>{ languageText.create_account }</NavLinks>
						<NavLinks to={ `/${ languageCode }/benefits` } title={ languageText.benefits } className='link-primary'>{ languageText.benefits }</NavLinks>
					</div>
				</Wrapper>
				<Wrapper>
					<div>{ languageText.help }</div>
					<div>
						<NavLinks to={ `/${ languageCode }/how-to-order` } title={ languageText.how_to_order } className='link-primary'>{ languageText.how_to_order }</NavLinks>
						<NavLinks to={ `/${ languageCode }/order-status` } title={ languageText.order_status } className='link-primary'>{ languageText.order_status }</NavLinks>
						<NavLinks to={ `/${ languageCode }/contact-us` } title={ languageText.contact_us } className='link-primary'>{ languageText.contact_us }</NavLinks>
					</div>
				</Wrapper>
				<Wrapper>
					<div>{ languageText.policy }</div>
					<div>
						<NavLinks to={ `/${ languageCode }/privacy-policy` } title={ languageText.privacy_policy } className='link-primary'>{ languageText.privacy_policy }</NavLinks>
						<NavLinks to={ `/${ languageCode }/cookies-policy` } title={ languageText.cookies_policy } className='link-primary'>{ languageText.cookies_policy }</NavLinks>
						<NavLinks to={ `/${ languageCode }/terms-and-conditions` } title={ languageText.terms_and_conditions } className='link-primary'>{ languageText.terms_and_conditions }</NavLinks>
					</div>
				</Wrapper>
			</Navigation>
			<Subscribe>
				<Head>
					<div>{ languageText.subscribe_enews_promotions.heading }</div>
					<div>{ languageText.subscribe_enews_promotions.description }</div>
				</Head>
				<form ref={ formRef } onSubmit={ submitSubscribe }>
					<input type='email' ref={ emailRef } title={ languageText.email } placeholder='example@mail.com' className='input' />
					<input type='submit' value={ languageText.subscribe } title={ languageText.subscribe } className='button-secondary' />
				</form>
			</Subscribe>
			<Law>
				<div>{ languageRegistered }</div>
				<div>{ languageCopyright }</div>
			</Law>
		</Footers>
	);
}

export default Footer;