import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// #region Styled

const Wrapper = styled.div`
	padding: var(--spacing-unit-default) var(--spacing-unit-s1) 0;
	display: flex;
	justify-content: center;
	column-gap: var(--spacing-unit-s3);
	
	p, a {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: var(--black-color);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	a {
		min-width: 37px;
		padding: 6.5px var(--spacing-unit-s2);
		border-radius: var(--border-radius);
	}
	
	.selected {
		font-weight: bold;
		color: var(--white-color);
		background-color: var(--primary-color);
	}
`;

const Links = styled(Link)<{ $icon?: string; }>`
	&::before {
		content: var(--${ props => props.$icon });
		font-size: var(--font-size-l1);
	}
	
	@media (hover: hover) {
		&:hover:not(.selected) {
			background-color: var(--secondary-color);
		}
	}
	
	&:focus {
		outline: none;
	}
	
	&:focus:not(.selected) {
		background-color: var(--secondary-color);
	}
`;

// #endregion

const Pagination = (props: { all: number, page: number }) => {
	const { state } = useContext(dataContext) as Types;
	const { text: languageText } = state.language as Types;
	
	return (
		<Wrapper>
			{ (props.all > 1 && props.page > 1) &&
				<Links to={ `?pag=${ props.page-1 }` } title={ languageText.next } className='bophloi' $icon='left-arrow'></Links>
			}
			<Links to='#' title={ `${ languageText.page } 1` } className='selected' tabIndex={ -1 }>1</Links>
			<Links to='?pag=2' title={ `${ languageText.page } 2` }>2</Links>
			<Links to='?pag=3' title={ `${ languageText.page } 3` }>3</Links>
			<p>...</p>
			<Links to='?pag=5' title={ `${ languageText.page } 5` }>5</Links>
			{ (props.all > 1 && props.page < props.all) &&
				<Links to={ `?pag=${ props.page+1 }` } title={ languageText.next } className='bophloi' $icon='right-arrow'></Links>
			}
		</Wrapper>
	);
}

export default Pagination;