import styled from 'styled-components';

// #region ProductList

const ProductList = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
	
	.head, .option div, .info, .default div, .image, .title div, .price, .stock {
		background-color: var(--soft-gray-color);
	}
	
	.filter {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: var(--spacing-unit-default);
	}
	
	.head {
		height: var(--line-height-l1);
		width: 35%;
	}
	
	.wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		row-gap: var(--spacing-unit-s2);
	}
	
	.option {
		display: flex;
		column-gap: var(--spacing-unit-s1);
	}
	
	.option div {
		height: 37px;
		border-radius: var(--border-radius);
	}
	
	.option div:first-child {
		flex: 1;
	}
	
	.option div:nth-child(2) {
		flex: 2;
	}
	
	.option div:last-child {
		width: 37px;
	}
	
	.info {
		height: var(--line-height-s1);
		width: 35%;
	}
	
	.default {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.default div:first-child {
		height: var(--line-height-l1);
		width: 35%;
	}
	
	.default div:last-child {
		height: var(--line-height-default);
		width: 25%;
	}
	
	.list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: var(--spacing-unit-s2);
	}
	
	.item {
		border: 1px solid var(--soft-gray-color);
		border-radius: var(--border-radius);
		overflow: hidden;
	}
	
	.image {
		aspect-ratio: 1;
	}
	
	.description {
		padding: var(--spacing-unit-s2);
		display: flex;
		flex-direction: column;
		row-gap: var(--spacing-unit-s4);
	}
	
	.title div:first-child {
		height: var(--line-height-s1);
		width: 100%;
	}
	
	.title div:last-child {
		height: var(--line-height-s1);
		width: 75%;
	}
	
	.price {
		height: 26px;
		width: 50%;
	}
	
	.stock {
		height: var(--line-height-s1);
		width: 50%;
	}
`;

// #endregion

// #region Pagination

const Pagination = styled.div`
	padding: var(--spacing-unit-default) var(--spacing-unit-s1) 0;
	display: flex;
	justify-content: center;
	column-gap: var(--spacing-unit-s3);
	
	div {
		height: 37px;
		width: 37px;
		background-color: var(--soft-gray-color);
		border-radius: var(--border-radius);
	}
`;

// #endregion

const Skeleton = (props: { type: string, option?: string, limit?: number }) => {
	return (
		<>
			{(() => {
				switch(props.type) {
					case 'product_list':
						return (
							<ProductList>
								{(() => {
									switch(props.option) {
										case 'filter':
											return (
												<div className='filter'>
													<div className='head'></div>
													<div className='wrapper'>
														<div className='option'>
															<div></div>
															<div></div>
															<div></div>
														</div>
														<div className='info'></div>
													</div>
												</div>
											);
										default:
											return (
												<div className='default'>
													<div></div>
													<div></div>
												</div>
											);
									}
								})()}
								<div className='list'>
									{[...Array(props.limit)].map((x, i) =>
										<div key={ i } className='item'>
											<div className='image'></div>
											<div className='description'>
												<div className='title'>
													<div></div>
													<div></div>
												</div>
												<div className='price'></div>
												<div className='stock'></div>
											</div>
										</div>
									)}
								</div>
							</ProductList>
						);
					case 'pagination':
						return (
							<Pagination>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</Pagination>
						);
					default:
						return null;
				}
			})()}
		</>
	);
}

export default Skeleton;