import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	// #region root style
	
	:root {
		--font-size-l2: 24px;
		--font-size-l1: 19px;
		--font-size-default: 15px;
		--font-size-s1: 12px;
		--font-size-s2: 9px;
		
		--line-height-l2: 39px;
		--line-height-l1: 31px;
		--line-height-default: 24px;
		--line-height-s1: 19px;
		--line-height-s2: 15px;
		
		--spacing-unit-l1: 39px;
		--spacing-unit-default: 24px;
		--spacing-unit-s1: 15px;
		--spacing-unit-s2: 9px;
		--spacing-unit-s3: 6px;
		--spacing-unit-s4: 4px;
		
		--primary-color: #642DD2;
		--secondary-color: #EFEAFB;
		--red-color: #D22D2D;
		--green-color: #2DD22D;
		--black-color: #14092A;
		--dark-gray-color: #726F76;
		--gray-color: #BFBDC1;
		--soft-gray-color: #F2F2F3;
		--white-color: #FFFFFF;
		
		--bright-primary-color: #5706F9;
		--bright-red-color: #F90606;
		
		--border-radius: 4px;
		--natural-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.08);
		--opacity: 75%;
		--spacing-box-unit: 2px 6px;
		--spacing-label-unit: 1.5px 5px;
	}
	
	* {
		box-sizing: border-box;
	}
	
	html, body, #root {
		height: 100%;
	}
	
	body {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		margin: 0;
	}
	
	#root {
		max-width: 414px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
	}
	
	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	}
	
	.fill-top, .fill-bottom {
		padding-top: 53px;
	}
	
	.safearea-bottom {
		height: calc(env(safe-area-inset-bottom) - 5px);
	}
	
	// #endregion
	
	// #region reset style
	
	ul, ol {
		margin-block: unset;
	}
	
	blockquote {
		margin-block: unset;
		margin-inline: unset;
	}
	
	h1, h2, h3, h4, h5, h6, P {
		margin-block: unset;
	}
	
	a {
		text-decoration: none;
	}
	
	input, button, select {
		font-family: inherit;
		appearance: unset;
		padding: unset;
		border: unset;
	}
	
	option {
		padding-block: unset;
		min-block-size: unset;
		padding-inline: unset;
	}
	
	// #endregion
	
	// #region button, link style
	
	.button-primary, .button-ghost, .button-special, .link-primary {
		--default-color: #642DD2;
		--hover-color: #461F93;
		--press-color: #281254;
		--disable-color: #BFBDC1;
	}
	
	.button-secondary, .button-event, .link-secondary {
		--default-color: #726F76;
		--hover-color: #4C4A4F;
		--press-color: #262527;
		--disable-color: #BFBDC1;
	}
	
	.button-primary, .button-secondary, .button-ghost, .button-special, .button-event, .button-icon {
		cursor: pointer;
		outline: none;
		user-select: none;
	}
	
	.button-primary, .button-secondary {
		font-size: var(--font-size-default);
		font-weight: bold;
		line-height: var(--line-height-default);
		color: var(--white-color);
		padding: var(--spacing-unit-s2) var(--spacing-unit-s1);
		border-radius: var(--border-radius);
	}
	
	.button-primary, .button-secondary {
		background-color: var(--default-color);
	}
	
	.button-ghost {
		font-size: var(--font-size-default);
		font-weight: bold;
		line-height: var(--line-height-default);
		color: var(--primary-color);
		background-color: transparent;
		padding: var(--spacing-unit-s2) var(--spacing-unit-s1);
		border: 1px solid var(--primary-color);
		border-radius: var(--border-radius);
	}
	
	.button-special {
		font-size: var(--font-size-s1);
		font-weight: bold;
		color: var(--primary-color);
		padding: var(--spacing-unit-s2) 12px;
		background-color: var(--secondary-color);
		border-radius: var(--border-radius);
		display: flex;
		align-items: center;
		column-gap: var(--spacing-unit-s3);
		
		&::before {
			font-size: var(--font-size-l1);
		}
	}
	
	.button-event {
		font-size: var(--font-size-l1);
		color: var(--white-color);
		padding: var(--spacing-unit-s2);
		background-color: var(--dark-gray-color);
		opacity: var(--opacity);
		border-radius: 100%;
	}
	
	.button-icon {
		font-size: var(--font-size-l2);
		color: var(--black-color);
		background-color: transparent;
		padding: 6.5px;
	}
	
	@media (hover: hover) {
		.button-primary:hover, .button-secondary:hover, .button-event:hover {
			background-color: var(--hover-color);
		}
		
		.button-ghost:hover, .button-special:hover {
			color: var(--hover-color);
		}
		
		.button-ghost:hover {
			border-color: var(--hover-color);
		}
	}
	
	.button-primary:focus, .button-secondary:focus, .button-event:focus {
		background-color: var(--hover-color);
	}
	
	.button-ghost:focus, .button-special:focus {
		color: var(--hover-color);
	}
	
	.button-ghost:focus {
		border-color: var(--hover-color);
	}
	
	.button-primary:active, .button-secondary:active, .button-event:active {
		background-color: var(--press-color);
	}
	
	.button-ghost:active, .button-special:active {
		color: var(--press-color);
	}
	
	.button-ghost:active {
		border-color: var(--hover-color);
	}
	
	.link-primary {
		color: var(--black-color);
	}
	
	.link-secondary {
		color: var(--dark-gray-color);
	}
	
	@media (hover: hover) {
		.link-primary:hover, .link-secondary:hover {
			text-decoration: underline;
			color: var(--hover-color);
		}
	}
	
	.link-primary:focus, .link-secondary:focus {
		text-decoration: underline;
		color: var(--hover-color);
		outline: none;
	}
	
	// #endregion
	
	// #region select style
	
	.select-primary {
		font-size: var(--font-size-s1);
		font-weight: bold;
		line-height: var(--line-height-s1);
		color: var(--black-color);
		padding: 8px 12px;
		background-color: var(--white-color);
		border: 1px solid var(--gray-color);
		border-radius: var(--border-radius);
	}
	
	@media (hover: hover) {
		.select-primary:hover {
			border: 1px solid var(--dark-gray-color);
		}
	}
	
	.select-primary:focus {
		border: 1px solid var(--dark-gray-color);
		outline: none;
	}
	
	/*.dropdown-primary, .dropdown-special {
		position: relative;
	}
	
	.dropdown-primary button, .dropdown-special button {
		cursor: pointer;
		user-select: none;
	}
	
	.dropdown-primary button {
		width: 100%;
		padding: 8px 12px;
		background-color: var(--white-color);
		border: 1px solid var(--gray-color);
		border-radius: var(--border-radius);
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: var(--spacing-unit-s2);
	}
	
	.dropdown-special button {
		font-size: var(--font-size-s1);
		color: var(--black-color);
		background-color: var(--white-color);
		height: 24px;
		width: 24px;
		border: 1px solid var(--gray-color);
	}
	
	.dropdown-primary button:focus {
		border: 1px solid var(--dark-gray-color);
		outline: none;
	}
	
	.dropdown-special button:focus {
		outline: none;
	}
	
	.dropdown-primary :where(p, span) {
		pointer-events: none;
	}
	
	.dropdown-primary p {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: var(--black-color);
	}
	
	.dropdown-primary span {
		font-size: var(--font-size-default);
	}
	
	.dropdown-primary span::before {
		content: var(--down-arrow);
	}
	
	.dropdown-primary ul, .dropdown-special ul {
		list-style: none;
		padding-inline: unset;
		cursor: pointer;
	}
	
	.dropdown-primary :where(div, ul) {
		width: 100%;
	}
	
	.dropdown-primary :where(div, ul), .dropdown-special ul {
		background-color: var(--white-color);
		border-radius: var(--border-radius);
		overflow: hidden;
		margin-top: var(--spacing-unit-s4);
		box-shadow: var(--natural-shadow);
		display: none;
		position: absolute;
		z-index: 1;
	}
	
	.dropdown-primary .show {
		display: block;
	}
	
	.dropdown-primary :where(a, li), .dropdown-special li {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: var(--black-color);
		padding: 8px 12px;
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;
		display: block;
	}
	
	.dropdown-primary :where(a, li):not(:last-child) {
		border-bottom: 1px solid var(--soft-gray-color);
	}
	
	@media (hover: hover) {
		.dropdown-primary :where(a, li):hover:not(.selected) {
			background-color: var(--secondary-color);
		}
	}
	
	.dropdown-primary :where(a, li):focus {
		outline: none;
	}
	
	.dropdown-primary :where(a, li):focus:not(.selected) {
		background-color: var(--secondary-color);
	}
	
	.dropdown-primary .selected {
		font-weight: bold;
		color: var(--primary-color);
	}*/
	
	// #endregion
	
	// #region content style
	
	.content {
		padding: var(--spacing-unit-default) 19px 0;
		display: flex;
		flex-direction: column;
		row-gap: var(--spacing-unit-default);
	}
	
	.content h1 {
		font-size: var(--font-size-l1);
		line-height: var(--line-height-l1);
		color: var(--black-color);
		text-align: center;
	}
	
	.content section {
		display: flex;
		flex-direction: column;
		row-gap: var(--spacing-unit-s1);
	}
	
	.content h2 {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: var(--black-color);
	}
	
	.content :where(p, blockquote, li) {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: var(--dark-gray-color);
	}
	
	.content a {
		text-decoration: underline;
		color: var(--primary-color);
	}
	
	.content small {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
	}
	
	.content br:nth-of-type(2) {
		content: "";
		height: var(--spacing-unit-s1);
		width: fit-content;
		display: block;
	}
	
	// #endregion
	
	// #region input style
	
	.input {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: var(--black-color);
		padding: 8px var(--spacing-unit-s1);
		border: 1px solid var(--gray-color);
		border-radius: var(--border-radius);
	}
	
	.input::placeholder {
		color: var(--gray-color);
	}
	
	@media (hover: hover) {
		.input:hover {
			border: 1px solid var(--dark-gray-color);
		}
	}
	
	.input:focus {
		border: 1px solid var(--dark-gray-color);
		outline: none;
	}
	
	// #endregion
	
	// #region Other style
	
	.line, .stroke {
		padding: 0 var(--spacing-unit-s1);
	}
	
	.stroke {
		padding-top: var(--spacing-unit-s1);
	}
	
	.line::before, .stroke::before {
		height: 1px;
		background-color: var(--soft-gray-color);
		display: block;
		content: "";
	}
	
	.groove {
		padding-top: var(--spacing-unit-default);
	}
	
	.groove::before {
		height: var(--spacing-unit-s3);
		background-color: var(--secondary-color);
		border-top: 1px solid var(--soft-gray-color);
		border-bottom: 1px solid var(--soft-gray-color);
		display: block;
		content: "";
	}
	
	.eyes::before {
		content: var(--eyes);
	}
	
	.eyes-closed::before {
		content: var(--eyes-closed);
	}
	
	// #endregion
`;

export default GlobalStyle;