const content = `<h1>Cookies policy</h1>
<p>When you enter the web application ( { domain } ) information related to your entry into the web application. Will be saved in the form of cookies. This cookies policy explains the meaning function, purpose, including deleting and refusing to store cookies. For your privacy by entering this web application you are giving us permission to use cookies. According to the cookies policy with the following details.</p>
<section>
	<h2>What are cookies</h2>
	<p>Cookies are small files that store information about web application usage such as date and time, links clicked, pages visited. Various setting conditions it will be recorded on your computer or accessible communication devices such as notebooks, tablets or smartphones through your web browser as you enter the web application. Cookies do not cause any harm to your computer equipment or communication tools. In the following cases your personal information may be stored to enhance your online experience. It will remember the uniqueness of the language and customize usage data according to your needs it is a confirmation of unique characteristics. Your security information including the services you are interested. Cookies are also used to measure online traffic. Personalizing content according to your use by considering previous or current usage behavior and may have advertising purposes.</p>
	<blockquote cite="https://allaboutcookies.org">You can find more information about cookies at <a href="https://allaboutcookies.org" target="_blank">allaboutcookies.org</a></blockquote>
</section>
<section>
	<h2>How does { name } use cookies</h2>
	<p>{ name } uses cookies to record your visit and sign up for your web application making it easier for { name } to remember your web application usage. And this information will be used to improve { name } web application to better suit your needs. To facilitate the speed of your web application { name } may in some cases require third parties to do so. This may require the use of internet protocol addresses ( IP Address ) and cookies for statistical analysis. As well as linking information and processed for marketing purposes.</p>
</section>
<section>
	<h2>Cookies used by { name } may be divided into 2 types according to their storage as follows</h2>
	<ol>
		<li><u>Session Cookies</u> are temporary cookies that remember you. During your visit to the { name } web application for example the language you have set and selected. Will be deleted from your computer or device. When you exit the web application or have closed the web browser.<br><br></li>
		<li><u>Persistent Cookies</u> are cookies that last for a specified period of time or until you delete it. This type of cookies allows { name } web application to remember you and preferences when you return to use the web application. Will help you access the web application service more conveniently and quickly.</li>
	</ol>
</section>
<section>
	<h2>The purposes of using cookies that { name } uses are as follows</h2>
	<ul>
		<li><u>Strictly Necessary Cookies</u> These types of cookies are necessary to provide the { name } web application so that you can use it in various parts of web applications including helping remember information you've provided through web applications. Disabling these types of cookies will result in you being unable to use the essential services of { name } which require this type of cookies to be used.<br><br></li>
		<li><u>Performance Cookies</u> These cookies help { name } understand user interactions. In using { name } web application services including pages or which areas of the web application are popular. As well as other data analysis { name } also uses this data to improve the functionality of the web application. And to better understand user behavior even though the information this cookies collects is non-personally identifiable information and used for statistical analysis only. Disabling this type of cookies will result in { name } being unable to know the volume of visitors to the web application and cannot evaluate the quality of service.<br><br></li>
		<li><u>Functional Cookies</u> This type of cookies helps { name } web applications remember your choices that you have set and help web applications deliver features and additional content to suit your use. Such as helping to remember your user account name or remember changes to font size settings or various page settings that you can customize. Disabling these types of cookies this may result in the web application being unable to function completely.<br><br></li>
		<li><u>Targeting Cookies</u> This type of cookies is a cookies that is created by linking third-party websites or web applications. Which stores website or web applications usage information that you have visited to present products or services on the another website or web application that is not a web application of { name }. However if you disable this type of cookies. It will not affect the use of { name } web application. But it will affect the presentation of products or services on the other website or web applications not aligned with your interests.</li>
	</ul>
</section>
<section>
	<h2>How can you manage cookies</h2>
	<p>Most browsers by default cookies are accepted. However you can decline their use or delete cookies in the settings page of the browser you are using. Your browser may affect the format and use on our web application page. If you wish to modify the settings you can check more details at the link provided below.</p>
	<ul>
		<li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Google Chrome</a></li>
		<li><a href="https://support.apple.com/en-gb/HT201265" target="_blank">Safari ( iPhone )</a></li>
		<li><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank">Safari ( Mac )</a></li>
		<li><a href="https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies" target="_blank">Mozilla Firefox</a></li>
		<li><a href="https://www.samsung.com/uk/support/mobile-devices/what-are-cookies-and-how-do-i-enable-or-disable-them-on-my-samsung-galaxy-device/" target="_blank">Samsung Internet ( Mobile )</a></li>
		<li><a href="https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank">Microsoft Edge</a></li>
		<li><a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Internet Explorer</a></li>
		<li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a></li>
	</ul>
	<p>Please note that. If you choose to disable cookies on your browser or device. It may affect the functionality of some parts of the { name } web application that may not work or provide services as normal.</p>
	<p>{ name } is not responsible and is not related including the content on the various website or web applications mentioned above.</p>
	<blockquote cite="https://www.aboutcookies.org/how-to-delete-cookies">For other information more on this. You can read at <a href="https://www.aboutcookies.org/how-to-delete-cookies" target="_blank">www.aboutcookies.org/how-to-delete-cookies</a></blockquote>
</section>
<section>
	<h2>Linking information to the another website or web application</h2>
	<p>{ name } web applications may contain links to the Website, web application or social media of third parties. Including the content may be embedded or videos that come from social media such as YouTube, Facebook or etc. Which will help you access the content and create interactions with other people on social media from { name } web application. Which is a website, web application or social media of third parties will be determined and set cookies itself. Which { name } cannot control or responsible for those cookies. I recommend that you go read it and study policy or announce the use of cookies by those third parties as well.</p>
</section>
<section>
	<h2>Announcement changes</h2>
	<p>This announcement may be updated accordingly and consistent with the situation according to the actual service provided. { name } there will be new announcements on this web application. Therefore { name } recommends that you make sure you understand the changes to these terms.</p>
</section>
<section>
	<h2>Contact { name }</h2>
	<p>In case you have any questions about our cookies policy. You can inquire at</p>
</section>
<p><small>This policy is effective from 1 June 2022</small></p>`;

export default content;