const useCookie = {
	get: (name: string): object => { /* wac-config */
		const text = `${ name }=`;
		const array = document.cookie.split(';');
		let result = {};
		
		for(let i = 0; i < array.length; i++) {
			const items = array[i].trim();
			
			if (items.indexOf(text) === 0) {
				result = JSON.parse(items.substring(text.length));
				break;
			}
		}
		
		return result;
	},
	
	create: (name: string, value: string, expire: number) => { /* wac-config, {"lang":"th"}, 180 */
		const date = new Date();
		date.setTime(date.getTime()+(expire*24*60*60*1000));
		const expires = `expires=${ date.toUTCString() }`;
		
		document.cookie = `${ name }=${ value }; ${ expires }; path=/; secure; samesite=lax`;
	}
}

export default useCookie;