import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useHandler from '../hooks/useHandler';
import ProductList from '../components/ProductList'

// #region Styled

const Carousel = styled.div`
	background-color: var(--soft-gray-color);
	aspect-ratio: 1;
	max-width: inherit;
	position: relative;
	
	ul {
		list-style: none;
		padding-inline: unset;
	}
`;

const Fixed = styled.div`
	width: 100%;
	max-width: inherit;
	padding: var(--spacing-unit-s1) var(--spacing-unit-s1) 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	z-index: 1;
	
	div {
		display: flex;
		column-gap: var(--spacing-unit-s1);
	}
`;

const Backward = styled.button`
	&::before {
		content: var(--left-arrow);
		position: relative;
		left: -1px;
	}
`;

const Share = styled.button`
	&::before {
		content: var(--share);
		position: relative;
		top: -1px;
	}
`;

const Favorite = styled.button`
	&::before {
		content: var(--favorite);
		position: relative;
		top: 1px;
	}
`;

const Image = styled.ul`
	img {
		height: auto;
		width: 100%;
		display: block;
	}
`;

const Dot = styled.ul`
	display: flex;
	justify-content: center;
	column-gap: var(--spacing-unit-s3);
	position: absolute;
	bottom: var(--spacing-unit-s1);
	left: 50%;
	transform: translate(-50%, 0);
	
	li {
		height: var(--spacing-unit-s3);
		width: var(--spacing-unit-s3);
		border-radius: 100%;
		background-color: var(--gray-color);
		opacity: var(--opacity);
	}
	
	.selected {
		background-color: var(--primary-color);
	}
`;

const Information = styled.div`
	padding: var(--spacing-unit-default) var(--spacing-unit-s1) 0;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
`;

const Code = styled.div`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: var(--dark-gray-color);
`;

const Title = styled.h1`
	font-size: var(--font-size-l1);
	line-height: var(--line-height-l1);
	color: var(--black-color);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

const Value = styled.div<{ $name?: string; }>`
	display: flex;
	align-items: center;
	
	div {
		${ (props) => {
			switch(props.$name) {
				case 'special-price':
					return 'color: var(--bright-red-color);';
				default:
					return 'color: var(--bright-primary-color);';
			}
		} }
	}
	
	span {
		font-size: var(--font-size-default);
		font-weight: normal;
	}
	
	span:first-child {
		margin-left: var(--spacing-unit-s4);
	}
	
	span:nth-of-type(2) {
		color: var(--dark-gray-color);
		text-decoration: line-through;
		margin-left: var(--spacing-unit-s3);
	}
`;

const Symbol = styled.div`
	font-size: var(--font-size-default);
	position: relative;
	top: -2px;
`;

const Price = styled.div`
	font-size: 21px;
	font-weight: 500;
	line-height: 33px;
	display: flex;
	align-items: center;
`;

const Tag = styled.div<{ $name?: string; }>`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: var(--white-color) !important;
	padding: var(--spacing-box-unit);
	border-radius: var(--border-radius);
	margin-left: var(--spacing-unit-s3);
	
	${ (props) => {
		switch(props.$name) {
			case 'off-price':
				return 'background-color: var(--red-color);';
			default:
				return null;
		}
	} }
`;

const Stock = styled.div`
	font-size: var(--font-size-default);
	line-height: var(--line-height-default);
	color: var(--dark-gray-color);
	
	span {
		text-decoration: underline;
	}
`;

const Description= styled.div`
	padding: var(--spacing-unit-s1) var(--spacing-unit-s1) 0;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
	
	div {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
	}
	
	div:first-child {
		font-weight: bold;
		color: var(--black-color);
	}
	
	div:last-child {
		color: var(--dark-gray-color);
	}
`;

// #endregion

const ProductDescription = (props: { helmet: boolean }) => {
	const { state } = useContext(dataContext) as Types;
	const { text: languageText } = state.language as Types;
	const navigate = useNavigate();
	
	return (
		<>
			{ props.helmet &&
				<Helmet>
					<title>รายละเอียดสินค้า - BOKOus</title>
					<meta name='description' content='รายละเอียดสินค้า...' />
					<style>{`
						.fill-top {
							display: none
						}
						
						.bottom-action {
							display: block;
						}
						
						.top-navigation, .bottom-navigation {
							display: none;
						}
					`}</style>
				</Helmet>
			}
			<Carousel>
				<Fixed>
					<Backward type='button' title={ languageText.backward } className='button-event bophloi' onClick={ () => navigate(-1) }></Backward>
					<div>
						<Share type='button' title={ languageText.share } className='button-event bophloi'></Share>
						<Favorite type='button' title={ languageText.favorite } className='button-event bophloi'></Favorite>
					</div>
				</Fixed>
				<Image>
					<li><img src='/images/products-item/5923773.webp' alt='รูปภาพสินค้า' onLoad={ useHandler.image } onError={ useHandler.image } /></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</Image>
				<Dot>
					<li className='selected'></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</Dot>
			</Carousel>
			<div className='line'></div>
			<Information>
				<Code>รหัสสินค้า : 5923773</Code>
				<Title>หัวข้อสินค้าที่มีขนาดความยาวไม่เกิน 55-75 ตัวอักษร ขนาด 19px ตัวหนา สีดำ</Title>
				<Value $name='special-price'>
					<Symbol>฿</Symbol>
					<Price>199<span>/ชิ้น</span><span>{ languageText.list_price } ฿299</span></Price>
					<Tag $name='off-price'>-฿100</Tag>
				</Value>
				<Stock>{ languageText.in_stock }</Stock>
			</Information>
			<div className='stroke'></div>
			<Description>
				<div>คำอธิบายสินค้า</div>
				<div>คำอธิบายสินค้า</div>
			</Description>
			<div className='groove'></div>
			<ProductList title='สินค้าที่อาจสนใจ' query='?caf=category-first' />
		</>
	);
}

export default ProductDescription;