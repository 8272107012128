import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import PromotionCarousel from '../components/PromotionCarousel';
import ProductList from '../components/ProductList';

// #region Styled

const Main = styled.main`
	flex: 1;
`;

// #endregion

const Home = () => {
	const { state } = useContext(dataContext) as Types;
	const {
		head: languageHead,
		text: languageText
	} = state.language as Types;
	
	return (
		<>
			<Helmet>
				<title>{ languageHead.home.title }</title>
				<meta name='description' content={ languageHead.home.description } />
			</Helmet>
			<Main>
				<PromotionCarousel />
				<ProductList title={ languageText.recommended_products } query='' />
			</Main>
			<div className='groove'></div>
		</>
	);
}

export default Home;