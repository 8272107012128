import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

// #region Styled

const Main = styled.main`
	flex: 1;
`;

// #endregion

const CreateAccount = () => {
	const { state } = useContext(dataContext) as Types;
	const { head: languageHead } = state.language as Types;
	
	return (
		<>
			<Helmet>
				<title>{ languageHead.create_account.title }</title>
				<meta name='description' content={ languageHead.create_account.description } />
			</Helmet>
			<Main>
			</Main>
		</>
	);
}

export default CreateAccount;